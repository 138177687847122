<script lang="ts" setup>
import type { HtmlHTMLAttributes } from 'vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<{
	class?: HtmlHTMLAttributes['class'];
}>();
</script>

<template>
	<div :class="cn('grid  gap-sm  p-3xl text-center sm:text-left', props.class)">
		<slot />
	</div>
</template>
